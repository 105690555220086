@font-face {
    font-family: 'Signika';
    src: url('#{$font-url-path}/Signika-Regular.eot');
    src: local('Circular Std Book'),
        local('CircularStd-Book'),
        url('#{$font-url-path}/Signika-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$font-url-path}/Signika-Regular.woff2') format('woff2'),
        url('#{$font-url-path}/Signika-Regular.woff') format('woff'),
        url('#{$font-url-path}/Signika-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Signika';
    src: url('#{$font-url-path}/Signika-Bold.eot');
    src: local('Circular Std Book'),
        local('CircularStd-Book'),
        url('#{$font-url-path}/Signika-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$font-url-path}/Signika-Bold.woff2') format('woff2'),
        url('#{$font-url-path}/Signika-Bold.woff') format('woff'),
        url('#{$font-url-path}/Signika-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


$font-family-base:                  'Signika', Helvetica, Arial, sans-serif;
$headings-font-family:              'Signika', Helvetica, Arial, sans-serif;
