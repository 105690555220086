$asset-path:                        './';
$asset-path-2:                      './';
$image-url-path:                    $asset-path + 'images/';
$font-url-path:                     $asset-path-2 + 'fonts/';

$font-size-small:                   1.4rem !default; // 12px
$font-size-base:                    1.6rem !default; // 16px
$font-size-medium:                  1.8rem !default; // 24px
$font-size-large:                   2.2rem !default; // 32px
$font-size-extra-large:             2.8rem !default; // 40px
$font-size-extra-extra-large:       3.4rem !default; // 40px

$font-size-h1-display:              11rem !default;
$font-size-h1:                      6.2rem !default;
$font-size-h2-display:              8rem !default;
$font-size-h2:                      5rem !default;
$font-size-h3:                      2.4rem !default;
$font-size-h4:                      1.9rem !default;

$line-height-base:                  1.6 !default;
$line-height-computed:              floor(($font-size-base * $line-height-base)) !default;

$headings-line-height:              1.1 !default;
