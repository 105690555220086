$c: 'troef';

.#{$c} {
    text-align: center;
    position: relative;
    &--title{
        display: block;
        font-size: $font-size-extra-large;
        font-weight: bold;
    }

    &--icon{

        margin: 35px auto;
        height: 140px;

        img{
            width: 100%;
            height: 140px;
        }
    }
}
