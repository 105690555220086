$c: 'product';
$product--small: 360px;
$product--big: 735px;


.#{$c} {
    position: relative;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    .skew{
        display: none;
    }

    .product--content{
        display: none;
    }



    &.small{
        height: $product--small;
        margin-bottom: 15px;
        .skew {
            height: $product--small;
        }

        &:hover{
            .skew{
                  display: block;
                  position: absolute;
                  background: $color-dark-blue;
                  opacity: 0.7;
                  width: 60%;
                  -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
                  clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
                  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
                  z-index: 2;
            }

            .product--content{
                display: block;
                color: $color-white;
                position: absolute;
                top:45%;
                left:5%;
                transform: translate(-5%,-45%);
                z-index: 10;
                font-size: $font-size-extra-large;
            }
        }
    }

    &.big{
        height: $product--big;
        .skew {
            height: $product--big;
        }

        &:hover{
            .skew{
                  display: block;
                  position: absolute;
                  background: $color-dark-blue;
                  opacity: 0.7;
                  width: 100%;
                  height: calc(100% / 2);
                  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
                  z-index: 2;
                  bottom: 0;
            }

            .product--content {
                display: block;
                color: $color-white;
                position: absolute;
                top: 75%;
                left: 5%;
                transform: translate(-5%, -75%);
                z-index: 10;
                font-size: $font-size-extra-large;
            }
        }
    }
}
