html, body {
    // height: 100vh;
    margin: 0;
    overflow-x:hidden;
}

html {
    box-sizing: border-box;
    font-size: 54%;

    *, *:before, *:after {
        box-sizing: inherit;
    }
}



body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 1.2;
    color: $color-text;

    &.overflow {
        overflow: hidden;
    }

    &.overflow-x {
        overflow-x: hidden;
    }
}

.article{
    @include from($tablet) {
        padding: 0 80px 150px 0;
    }
  position: relative;
}



ul{
  list-style-type: none;

}
