$c: 'button';

.#{$c} {
    &--cta {
        text-decoration: none;
        background-color: $color-light-blue;
        color: $color-white;
        height: 120px;
        width: 80%;
        transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);

        @include mobile{
             width: 100%;
             margin-bottom: 10px;
        }

        text-align: center;
        display: block;
        border-radius: 10px;
        cursor: pointer;

        &:hover{
            background-color: $color-light-blue-07;
            transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
        }

        .icon{
            display: block;
             width: 75px;
             height: auto;
             margin: 0 auto;
             padding: 20px 0 10px 0;

             &--mail{
                 width: 60px;
             }
        }

        .text{
            display: block;
        }
    }
}
