h1,h2,h3,h4,h5,h6{
  font-weight: bold;
  font-family: $headings-font-family;
}

h1{
  font-size: $font-size-h1;
}

h2{
 font-size: $font-size-h2;
}


h3{
 font-size: $font-size-h3;
}


h4{
 font-size: $font-size-h4;
}
