$c: 'reach-us';

.#{$c} {

      h2{
        margin-top:0;
      }

    .content{
      margin-bottom: 30px;
      .item{
        position: relative;
        padding-left: 40px;
        font-size: $font-size-extra-large;
        @include mobile{
           font-size: $font-size-large;
        }
        margin-bottom: 20px;
        .icon{
            position: absolute;
            width: 25px;
            left: 0;
              top: 8px;
            img{
              width: 100%;
            }
        }

        a{
          text-decoration: none;
          color: black;
          &:hover{
            text-decoration: underline;
          }
        }


        &.location{
          .icon{
            width: 20px;
            top: 15px;
          }
        }
      }
    }

    .map--coordinates{display: none;}

    .map{
      width: 100%;
      height: 450px;
    }
}
