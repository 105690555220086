$c: 'section';
$header-height: 850px;

.#{$c} {

  &--header{
      padding-top:0;
      height: $header-height;
      overflow: hidden;
      background-color: $color-dark-blue;

      .columns, .container{
           height: $header-height;
      }

      .container{

          .arrow--down{
              position: absolute;
              bottom: 50px;
              z-index:11;
              width: 100%;
              text-align: center;
              -webkit-animation:bounce 1s infinite;

              @include mobile{

                    bottom: 10px;
              }

              img{
                width: 60px;
                height: 60px;
              }
          }

          @-webkit-keyframes bounce {
              0%       { bottom:5px; }
              25%, 75% { bottom:7.5px; }
              50%      { bottom:10px; }
              100%     {bottom:0;}
          }

          .columns{
              background-blend-mode: darken;
              // blend mode optional at this stage; will be used more in the next demo.
               transition: background 2s;

              .column{
                  padding: 0;
              }
              @include tablet{
                  .skew{
                        position: absolute;
                        background: $color-dark-blue;
                        opacity: 0.7;
                        height: $header-height;
                        width: 50%;
                        -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
                        clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
                        transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
                        z-index: 2;
                  }
               }

              .column--left{
                  z-index: 3;
                  padding: 5% 10% 5% 5%;
                  .logo{
                        margin: 45px auto;

                         @include mobile{
                             margin:10px;
                             width: 40%;
                        }
                  }

                  .intro{
                      margin: 0 auto;
                      color: $color-white;
                      font-size: $font-size-extra-large;

                       @include mobile{
                           background: $color-dark-blue;
                           padding:10px 15px;
                           margin: 50px 0;
                       }
                  }


              }

              .column--right{

              }
          }
      }
  }


  &--content{

  }

  &--title{
      text-transform: uppercase;

      @include mobile{
          text-align: center;
      }

  }

  &--intro{
      font-size: $font-size-extra-large;
  }

  &--cta{
      margin: 75px 0;
       background-color: $color-light-blue;
       text-align: center;
      .cta{
          display: block;
          padding: 75px 0;
          font-size: $font-size-h1;
          color: $color-white;
          text-decoration: none;
          font-weight: bold;
      }
  }

  &--contact{
      background-color: $color-grey;
      padding-bottom: 75px;
  }
}
