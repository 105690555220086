$c: 'nav--main';

.#{$c} {
    position: absolute;
    right: 50px;
    top: 50px;
    background: $color-dark-blue;
    opacity: 0.7;
    &__links{
        padding: 0 20px;
        li{
              display: inline-block;
              margin-left: 20px;
              margin-right: 20px;

              @include mobile{
                  display: block;
                  margin-bottom: 10px;

              }
        }
    }

    &__link{
        color: $color-white;
        text-decoration: none;
        font-size: $font-size-large;
        font-weight: normal;
        text-transform: uppercase;

         @include mobile{
             font-size: $font-size-large;
         }

        &:hover{
            border-bottom: 3px solid $color-light-blue;
        }
    }
}
