$c: 'form';

#{$c} {


}

.nf-form-fields-required{
    display: none;
}

.submit-wrap{
    input[type="button"] {
        cursor: pointer;
        background-color: $color-dark-blue !important;
        &:hover{
            background-color: $color-light-blue !important;
            color: $color-white !important;
        }
    }
}
