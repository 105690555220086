$c: 'footer';

.#{$c} {
    background: $color-dark-blue;
    padding-bottom: 50px;
    font-size: $font-size-large;
    color: $color-white;

    h2 {
        font-size:3rem;
    }

    .logo{
        margin-bottom: 40px;
        img{
            height: 120px;
            padding: 15px 0;
        }
    }

    a{
        color: white;
        text-decoration: none;

        &:hover{
             text-decoration: underline;
        }
    }
}



.footer-nav{
  padding-top: 2rem;

  &__link{
    display: block;
    text-decoration: none;
    padding: 4px 0;

    &:hover {
      text-decoration: underline;
    }

  }
}
